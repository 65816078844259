/* For different positions */
@media (min-width: 840px) {
.container:nth-child(4n + 1) .card {
    top: calc((100vh - 460px - 26px) * 0.685);
    left: calc((100vw - 670px) * 0.155);
    z-index: 4
}
  }
@media (min-width: 840px) {
.container:nth-child(4n + 2) .card {
    top: calc((100vh - 460px - 26px) * 0.906);
    left: calc((100vw - 670px) * 0.778);
    z-index: 3
}
  }
@media (min-width: 840px) {
.container:nth-child(4n + 3) .card {
    top: calc((100vh - 460px - 26px) * 0.369);
    left: calc((100vw - 670px) * 0.903);
    z-index: 2
}
  }
@media (min-width: 840px) {
.container:nth-child(4n + 4) .card {
    top: 100px;
    left: -2px;
    z-index: 5
}
  }

/* Hide the browser's default checkbox */
.card__checker {
  display: none;
}
/* Hide the card if checkbox not checked */
.card__checker ~ .card {
  display: none;
}
.card__checker ~ label > .card__open-sign {
  display: none;
}
/* Show the card if checkbox not checked */
.card__checker:checked ~ .card {
  display: block;
}
.card__checker:checked ~ label > .card__open-sign {
  display: inline;
}
.card__checker:checked ~ label > .card__close-sign {
  display: none;
}

.card__header {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 74px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

@media (max-width: 839px) {

.card__header {
    display: none
}
  }

.card__drug {
  width: 100%;
  padding: 24px;
  margin: 0;
  cursor: -webkit-grab;
  cursor: grab
}

.card__drug:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

.card__close {
  display: flex;
  justify-content: center;
  width: 74px;
  padding: 8px;
  cursor: pointer;
  background: none;
  border: none;
}

.container label {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

@media (max-width: 839px) {

.container label {
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    border-left: 2px solid var(--color-primary)
}
  }

@media (max-width: 839px) {

.container:last-child .card__checker ~ label {
    border-bottom: 2px solid var(--color-primary)
}
  }

.container:last-child .card__checker:checked ~ label {
  border-bottom: none;
}

.container:last-child .card {
  border-bottom: 2px solid var(--color-primary);
}

.card {
  float: left;
  width: 100%;
  overflow-x: hidden;
  color: black;
  background: white;
  border-right: 2px solid var(--color-primary);
  border-left: 2px solid var(--color-primary)
}
@media (min-width: 840px) {
.card {
    position: absolute;
    width: 670px;
    height: 460px;
    overflow-y: hidden;
    border: 2px solid var(--color-primary)
}
  }
@media (min-width: 840px) {
.card_large {
    height: calc(100vh - 158px)
}
  }
.card__body {
  padding-right: 12px;
  padding-left: 12px
}
@media (min-width: 840px) {
.card__body {
    height: 390px;
    padding-right: 24px;
    padding-left: 24px;
    overflow-y: scroll
}
  }
.card__body > *:last-child {
    margin-bottom: 68px
  }
@media (max-width: 399px) {
.card__body > *:last-child {
      margin-bottom: 48px
  }
    }
.card__body > *:first-child,
  .card__body > section:first-child > *:first-child {
    -webkit-margin-before: 8px;
            margin-block-start: 8px;
  }
@media (min-width: 840px) {
.card__body_large {
    height: calc(100vh - 232px)
}
  }

.plus {
  float: right;

  width: 32px;
  height: 32px
}

@media (min-width: 840px) {

.plus {
    width: 25px;
    height: 25px
}
  }

.plus:before {
  position: relative;
  top: 16px;

  display: block;

  width: 32px;
  height: 2px;

  content: '';
  background-color: var(--color-primary)
}

@media (min-width: 840px) {

.plus:before {
    top: 20px;

    width: 25px;
    height: 1px
}
  }

.plus:after {
  position: relative;
  bottom: 1px;
  left: 15px;

  display: block;

  width: 2px;
  height: 32px;

  content: '';
  background-color: var(--color-primary)
}

@media (min-width: 840px) {

.plus:after {
    top: 7px;
    left: 12px;

    width: 1px;
    height: 25px
}
  }

.minus {
  float: right;

  width: 32px;
  height: 32px
}

@media (min-width: 840px) {

.minus {
    width: 25px;
    height: 25px
}
  }

.minus:before {
  position: relative;
  top: 16px;

  display: block;

  width: 32px;
  height: 2px;

  content: '';
  background-color: var(--color-primary)
}

@media (min-width: 840px) {

.minus:before {
    top: 20px;

    width: 25px;
    height: 1px
}
  }

.cross {
  width: 60px;
  height: 60px;
  margin-top: -1px;
  background: white
    url("data:image/svg+xml,%3Csvg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2.00058' height='80.0233' transform='matrix(0.707108 -0.707105 0.707108 0.707105 0 1.41455)' fill='blue'/%3E%3Crect width='2.00058' height='80.0233' transform='matrix(-0.707108 -0.707105 0.707108 -0.707105 1.41455 58)' fill='blue'/%3E%3C/svg%3E%0A")
    no-repeat center 1px;
}

.up {
  right: 12px;
  bottom: 12px;

  z-index: 10000000;
  display: none;

  width: 40px;
  height: 40px;

  margin: 0;
  background: white
    url("data:image/svg+xml,%3Csvg width='34' height='33' viewBox='0 0 34 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='16' y='2' width='2' height='31' fill='blue'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0001 0.585938L33.7072 17.293L32.293 18.7073L17.0001 3.41436L1.70718 18.7073L0.292969 17.293L17.0001 0.585938Z' fill='blue'/%3E%3C/svg%3E%0A")
    no-repeat center 1px;
  border: 2px solid var(--color-primary)
}
@media (min-width: 840px) {
  .up {
    position: absolute
}
  }
@media (max-width: 839px) {
  .up {
    position: fixed
}
  }

.single-link {
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0px
}

.single-link > a,
  .single-link > button {
    padding: 7px 10px 3px 10px;
    margin-left: 12px;

    font: var(--text);
    color: var(--color-primary);

    background: white;
    border: 1px solid var(--color-primary);
    border-radius: 42px
  }

.single-link > a:hover, .single-link > button:hover {
      color: white;
      background-color: var(--color-primary);
    }

.link-line {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-after: 24px;
          margin-block-end: 24px
}

@media (max-width: 839px) {

.link-line {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px 16px
}

    .link-line > a:last-child {
      display: none;
    }
  }

.link-line > a:not(:last-child) {
    margin-right: 24px;
  }

.dead-screen {
  background-color: var(--color-primary);
}

.iframe {
  width: 100%;
  height: calc(100% - 74px);
  margin: 0px !important;
  border: none;
}

.app {
  background-color: white
}

.app label {
    font: var(--extra-large-text);
    color: var(--color-primary);
    background-color: white
  }

@media (min-width: 840px) {
      .app label > * {
        text-transform: lowercase;
      }

      .app label > span:first-child {
        margin-right: 2px;
      }
    }

@media (max-width: 839px) {

.app label {
      display: block;

      display: grid;
      grid-template-columns: 1fr 32px;
      align-items: center;
      width: 100%;
      padding: 24px 12px
  }

      .app label > span:first-child {
        height: 21px;
      }
    }

@media (max-width: 399px) {

.app label {
      padding: 20px 12px
  }

      .app label > span:first-child {
        height: 15px;
      }
    }

@media (min-width: 840px) {

.app {
    position: relative;
    float: left;
    height: 80px;
    padding: 20px 24px;
    margin: 24px;
    border: 2px solid var(--color-primary)
}

    .app > * {
      float: left
    }

      .app > *:not(:last-child) {
        margin-right: 24px;
      }
  }

@media (min-width: 840px) {

.about {
    padding-bottom: 0
}
  }

.about > * {
    font: var(--large-text);
  }

.experience h1,
  .experience h2,
  .experience h3,
  .experience h4,
  .experience h5,
  .experience h6 {
    -webkit-margin-before: 40px;
            margin-block-start: 40px;
    -webkit-margin-after: 8px;
            margin-block-end: 8px;
  }

.experience > h3 + p {
    font: var(--large-text);
  }

.projects h1,
  .projects h2,
  .projects h3,
  .projects h4,
  .projects h5,
  .projects h6 {
    -webkit-margin-before: 36px;
            margin-block-start: 36px;
    -webkit-margin-after: 12px;
            margin-block-end: 12px;
  }

.articles h1,
  .articles h2,
  .articles h3 {
    -webkit-margin-before: 80px;
            margin-block-start: 80px;
    -webkit-margin-after: 20px;
            margin-block-end: 20px;
  }

.articles h4,
  .articles h5,
  .articles h6 {
    -webkit-margin-before: 24px;
            margin-block-start: 24px;
    -webkit-margin-after: 12px;
            margin-block-end: 12px;
  }

.mustread {
  width: calc(100% + 4px);
  height: 100%;
  padding: 0px
}

@media (max-width: 839px) {

.mustread {
    display: none
}
  }

/* fonts */
@font-face {
  font-family: 'Steinbeck';
  font-style: normal;
  font-weight: normal;
  src: url('steinbeck-regular.4e17e618.woff2') format('woff2'),
    url('steinbeck-regular.b7718617.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Steinbeck';
  font-style: italic;
  font-weight: normal;
  src: url('steinbeck-italic.033fb8f7.woff2') format('woff2'),
    url('steinbeck-italic.3095293f.woff') format('woff');
  font-display: swap;
}
:root {
  --color-primary: blue;
  --color-secondary: rgb(255, 59, 106);
  --main-font: 'Steinbeck', -apple-system, BlinkMacSystemFont,
    'Apple Color Emoji', Arial, sans-serif;

  --header: 24px/110% var(--main-font);
  --subheader: 22px/110% var(--main-font);
  --extra-large-text: 40px/100% var(--main-font);
  --large-text: 24px/125% var(--main-font);
  --text: 18px/125% var(--main-font)
}
@media (max-width: 839px) {
:root {
    --extra-large-text: 26px/100% var(--main-font)
}
  }
@media (max-width: 399px) {
:root {
    --header: 20px/110% var(--main-font);
    --subheader: 17px/110% var(--main-font);
    --extra-large-text: 20px/100% var(--main-font);
    --large-text: 20px/125% var(--main-font);
    --text: 14px/125% var(--main-font)
}
  }
* {
  box-sizing: border-box
}
@media (min-width: 840px) {
*::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
*::-webkit-scrollbar-track{
    background-color: transparent;
  }
*::-webkit-scrollbar-corner{
    background-color: transparent;
  }
*::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
  }
* {
    scrollbar-color: transparent transparent;
    -ms-overflow-style: auto;
    scrollbar-width: thin

    /* not in standart, but so cute =) */
}
    *::-webkit-scrollbar {
      width: 4px;
    }

    *:hover::-webkit-scrollbar-thumb{
    background-color: blue;
  }

    *:hover::-webkit-scrollbar-track{
    background-color: transparent;
  }

    *:hover::-webkit-scrollbar-corner{
    background-color: transparent;
  }

    *:hover {
      scrollbar-color: blue transparent;
    }
  }
::-moz-selection {
  color: white;
  background-color: rgba(255, 59, 106, 0.99);
}
::selection {
  color: white;
  background-color: rgba(255, 59, 106, 0.99);
}
@media (min-width: 840px) {
.highlight {
    box-shadow: 0px 0px 40px rgba(255, 59, 106, 0.9)
}
  }
body {
  height: 100vh;
  margin: 0;
  font-family: 'Steinbeck', sans-serif;

  background-color: var(--color-primary)
}
@media (min-width: 840px) {
body {
    overflow: hidden;
    background-color: white
}
  }
ul {
  padding-left: 18px;
  list-style: square outside none;
}
li {
  padding: 6px 0;
  font: var(--text);
}
p {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
  font: var(--text);
}
a {
  color: var(--color-primary);
  text-decoration: none;
}
h4,
h5,
h6 {
  font: var(--subheader);
  font-weight: normal;
}
h1,
h2,
h3 {
  font: var(--header);
  font-weight: normal;
}
blockquote {
  display: inline;
  padding: 7px 10px 3px 10px;

  margin: 0px;
  font: var(--text);

  border: 1px solid #000000;
  border-radius: 39px;
}
h1 > blockquote,
h2 > blockquote,
h3 > blockquote,
h4 > blockquote,
h5 > blockquote,
h6 > blockquote {
  margin-left: 12px;
}
button:active {
  outline: none;
}
/* accessible */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
.focus-visible {
  --color-primary: var(--color-secondary);
  outline: none;
}

/*# sourceMappingURL=index.349f1bc7.css.map */
