.card__header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 74px;
  user-select: none;

  @media (--phoneDown) {
    display: none;
  }
}

.card__drug {
  width: 100%;
  padding: 24px;
  margin: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.card__close {
  display: flex;
  justify-content: center;
  width: 74px;
  padding: 8px;
  cursor: pointer;
  background: none;
  border: none;
}
