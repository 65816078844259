.container label {
  cursor: pointer;
  user-select: none;
  @media (--phoneDown) {
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    border-left: 2px solid var(--color-primary);
  }
}

.container:last-child .card__checker ~ label {
  @media (--phoneDown) {
    border-bottom: 2px solid var(--color-primary);
  }
}

.container:last-child .card__checker:checked ~ label {
  border-bottom: none;
}

.container:last-child .card {
  border-bottom: 2px solid var(--color-primary);
}
