.single-link {
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0px;

  & > a,
  & > button {
    padding: 7px 10px 3px 10px;
    margin-left: 12px;

    font: var(--text);
    color: var(--color-primary);

    background: white;
    border: 1px solid var(--color-primary);
    border-radius: 42px;

    &:hover {
      color: white;
      background-color: var(--color-primary);
    }
  }
}

.link-line {
  margin-block-start: 24px;
  margin-block-end: 24px;

  @media (--phoneDown) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px 16px;

    & > a:last-child {
      display: none;
    }
  }

  & > a:not(:last-child) {
    margin-right: 24px;
  }
}

.dead-screen {
  background-color: var(--color-primary);
}

.iframe {
  width: 100%;
  height: calc(100% - 74px);
  margin: 0px !important;
  border: none;
}
